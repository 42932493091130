<template>
  <div class="about">
    <img
      class="banner"
      :index="62"
      src="@/assets/images/about1/img_83821_0_0.png"
    />
    <div v-if="true" class="content" :index="60">
      <div v-if="true" class="block-1">
        <div class="about-container">
          <a class="tag-2" :index="42" href="#intro">平台简介 </a>
          <div class="vertical-line-3" :index="36" />
          <a class="label-2" :index="41" href="#service">产品与服务 </a>
        </div>
      </div>
      <div class="block-2">
        <div class="divider-wrapper">
          <img
            class="divider"
            :index="44"
            src="@/assets/images/about1/img_83821_0_1.png"
          />
          <img
            class="horizontal-line"
            :index="43"
            src="@/assets/images/about1/img_83821_0_2.png"
          />
        </div>
        <span id="intro" class="word-2" :index="40">平台简介 </span>
        <div class="section-wrapper" :index="35">
          <span class="section" :index="33"
            >曾氏通谱智慧平台介绍
            <br />
            “曾氏通谱智慧平台”是由湖南省曾氏通谱网络科技有限公司开发的“曾氏通谱APP”数字云平台。平台包含以下内容：曾子文化、曾氏文化、曾氏通谱、曾氏传媒、曾氏文创以及曾氏商业等。
            <br />
            平台以曾氏家谱数字化为切入点，实现海内外曾氏宗亲的快速聚合；以曾子文化和曾氏文化为依托，为曾氏家族成员构建一个精神家园；以曾氏有序实名制数据库为蓝海赋能曾氏企业。
            <br />
            湖南省曾氏通谱网络科技有限公司是由中国传统文化促进会曾子文化委员会授权正式注册成立，总部设在湖南省长沙市迎宾路38号二楼。公司秉承曾子文化委员会“联宗通谱，敦亲睦族”的文化理念，以文化创新为旗帜、科技创新为动力、专业人才为核心全方位打造曾氏文化商业生态，服务于海内外曾子后裔。
          </span>
        </div>
        <div
          style="width: 91%; display: flex; justify-content: space-between; margin-top: 20px; padding-left: 30px;box-sizing: border-box"
        >
          <img
            class="large-icon"
            :index="16"
            src="@/assets/images/about1/img_83821_0_8.png"
          />
          <img
            class="large-icon-1 large-icon"
            :index="19"
            src="@/assets/images/about1/img_83821_0_7.png"
          />
          <img
            class="large-icon-2 large-icon"
            :index="22"
            src="@/assets/images/about1/img_83821_0_4.png"
          />
          <img
            class="large-icon-3 large-icon"
            :index="21"
            src="@/assets/images/about1/img_83821_0_5.png"
          />
          <img
            class="large-icon-4 large-icon"
            :index="20"
            src="@/assets/images/about1/img_83821_0_6.png"
          />
        </div>
        <div class="container-2">
          <div class="container-inner">
            <span class="tag-3" :index="32">企业使命 </span>
            <span class="subtitle" :index="31">家风传承 谱泽后世 </span>
          </div>
          <div class="container-inner" style="margin-left: -10px;">
            <span class="organization" :index="30">企业愿景 </span>
            <span class="company" :index="29"
              >让优秀家族文化插上互联网的翅膀
            </span>
          </div>
          <div class="container-inner" style="margin-left: -10px;">
            <span class="label-3" :index="28">企业宗旨 </span>
            <span class="desc" :index="27">专注谱学 传承姓氏文化 </span>
          </div>
          <div class="container-inner" style="margin-left: 0px;">
            <span class="word-3" :index="26">企业目标 </span>
            <span class="summary" :index="25"
              >让每个家庭都拥有属于自己的历史
            </span>
          </div>
          <div class="container-inner">
            <span class="tag-4" :index="24">企业口号 </span>
            <span class="label-4" :index="23">让传统家谱活起来</span>
          </div>
        </div>
      </div>
      <div v-if="true" class="block-3">
        <img
          class="divider-1"
          :index="34"
          src="@/assets/images/about1/img_83821_0_3.png"
        />
        <span id="service" class="word-4" :index="39">产品与服务 </span>
        <img
          class="layer-2"
          :index="15"
          src="@/assets/images/about1/img_83821_0_9.jpeg"
        />
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped>
/* .about {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  width: 1920px;
  height: 2135px;
} */

.about {
  width: 1200px;
  margin: 0 auto;
}

.main {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  /* margin-top: 53px; */
  /* margin-right: 268px; */
  /* margin-left: 359px; */
}

.banner {
  position: relative;
  width: 1202px;
  height: 302px;
}

.content {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 5px;
  background-color: #ffffff;
  width: 1202px;
  overflow: auto;
}

.block-1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  height: 57px;
  line-height: 14px;
  white-space: nowrap;
}

.about-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  margin-top: 22px;
  margin-left: 41px;
}

.tag-2 {
  margin-top: -4px;
  margin-right: 18px;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0px;
  color: #c90a15;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.vertical-line-3 {
  margin-right: 19px;
  background-color: #efefef;
  width: 3px;
  height: 22px;
  overflow: auto;
  line-height: NaNpx;
  white-space: normal;
}

.label-2 {
  margin-top: -3px;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0px;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.block-2 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-top: 3px;
  width: 1202px;
  margin-bottom: 20px;
  /* height: 835px; */
}

.divider-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  margin-top: -3px;
  width: 1162px;
  height: 3px;
}

.divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 1162px;
  height: 3px;
}

.horizontal-line {
  position: absolute;
  top: -2px;
  left: 39px;
  width: 20px;
  height: 4px;
}

.word-2 {
  position: relative;
  margin-top: 26px;
  max-width: 1190px;
  height: 25px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  line-height: 24px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.section-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  border-radius: 4px;
  background-color: #f7f7f7;
  width: 1162px;
  /* height: 182px; */
  overflow: auto;
  line-height: 30px;
  flex: none;
  padding: 10px 0;
}

.section {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 5;
  margin-top: 1px;
  width: 1120px;
  /* height: 151px; */
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: normal;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.container-1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  margin-top: 22px;
}

.container-inner-i0 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 18px;
  width: 572px;
}

.overlayer {
  position: relative;
  border-radius: 4px;
  background-color: #333333;
  width: 572px;
  height: 302px;
  overflow: auto;
}

.info {
  position: relative;
  align-self: center;
  margin-top: 20px;
  text-align: left;
  text-decoration: none;
  line-height: 24px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.large-icon {
  width: 60px;
  height: 60px;
}

.container-inner-i1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: -2px;
  width: 572px;
}

.layer-1 {
  position: relative;
  border-radius: 4px;
  background-color: #333333;
  width: 572px;
  height: 302px;
  overflow: auto;
}

.meta {
  position: relative;
  align-self: center;
  margin-top: 20px;
  text-align: left;
  text-decoration: none;
  line-height: 24px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.large-icon-1 {
  width: 60px;
  height: 60px;
}

.container-2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 7px;
  line-height: 30px;
  margin-left: 20px;
  width: 93%;
  justify-content: space-between;
}

.container-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 120px;
  text-align: center;
  /* margin-top: 1px; */
  /* margin-right: 100px; */
}

.tag-3 {
  position: relative;
  max-width: 107px;
  height: 30px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.subtitle {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 2;
  position: relative;
  /* width: 119px; */

  height: 30px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: normal;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.container-inner-i0-1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1px;
  /* margin-right: 119px; */
  /* margin-right: 10px; */
  position: relative;
  left: -13px;
}

.organization {
  position: relative;
  max-width: 131px;
  height: 30px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.company {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 3;
  position: relative;
  width: 147px;
  height: 60px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.container-inner-i1-1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  left: -11px;
  /* margin-right: 102px; */
}

.label-3 {
  position: relative;
  /* margin-left: 37px; */
  max-width: 80px;
  height: 31px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.desc {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 3;
  position: relative;
  width: 154px;
  height: 60px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.container-inner-i2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 1px;
  position: relative;
  left: -20px;
  /* margin-right: 132px; */
}

.word-3 {
  position: relative;
  /* margin-left: 37px; */
  max-width: 80px;
  height: 30px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.summary {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 2;
  position: relative;
  width: 154px;
  height: 60px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.container-inner-1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1px;
}

.tag-4 {
  position: relative;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.label-4 {
  position: relative;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0px;
  white-space: pre;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.large-icon-2 {
  width: 74px;
  height: 74px;
}

.large-icon-3 {
  width: 74px;
  height: 74px;
}

.large-icon-4 {
  width: 74px;
  height: 74px;
}

.block-3 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-top: 3px;
}

.divider-1 {
  position: relative;
  margin-top: -3px;
  width: 1162px;
  height: 3px;
}

.word-4 {
  position: relative;
  margin-top: 27px;
  max-width: 1190px;
  height: 24px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  line-height: 24px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.layer-2 {
  position: relative;
  margin-top: 8px;
  width: 1014px;
  height: 573px;
}
</style>
